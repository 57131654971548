$color-white: #ffffff;
$color-light: #f1f5f9;
$color-black: #121212;
$color-night: #001632;

$color-red: #f44336;
$color-blue: #1a73e8;
$color-gray: #80868b;
$color-grayish: #dadce0;

$shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);

a,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
}

a {
  cursor: pointer;
}
img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

// Components
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 65px);
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    padding: 0 0.5rem;
  }
}

.text {
  font-family: inherit;
  line-height: inherit;
  text-transform: unset;
  text-rendering: optimizeLegibility;

  &-large {
    font-size: clamp(20px, 4vw, 28px);
    font-weight: 600;
    color: $color-black;
  }

  &-normal {
    font-size: clamp(13px, 2.5vw, 17px);
    font-weight: 400;
    color: $color-black;
    margin-top: 2px;
    margin-bottom: 2px;

    span {
      &:nth-child(2) {
        margin: 0 1rem;
      }
    }
  }

  &-links {
    font-size: clamp(13px, 2.5vw, 17px);
    font-weight: 400;
    color: $color-blue;
    margin: 0;

    &:hover {
      text-decoration: none;
    }
  }
}

.validate {
  font-size: clamp(12px, 2.5vw, 16px);
  margin: 0.8rem 0 0.5rem 0;
  color: red;

  @media screen and (max-width: 700px) {
    margin: 0.5rem 0 0.5rem 0;
  }
}

// Main
.main {
  background-image: url("../../public//images/login-background.webp");
  @media screen and (max-width: 768px) {
    background-image: none;
  }
  object-fit: cover;

  .wrapper {
    max-width: 28rem;
    width: 70%;
    padding: 1.5rem 2.5rem;
    border: none;
    outline: none;
    border-radius: 0.25rem;
    color: $color-black;
    background: $color-white;
    box-shadow: $shadow-large;

    @media screen and (max-width: 700px) {
      padding: 0.5rem 1rem;
    }

    @media screen and (max-width: 700px) {
      width: 90%;
    }

    .form {
      width: 100%;
      height: auto;
      margin-top: 0rem;

      .input-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
      }

      .input-field {
        font-family: inherit;
        font-size: clamp(13px, 2.5vw, 16px);
        font-weight: 400;
        line-height: inherit;
        width: 100%;
        height: auto;
        padding: 0.75rem 1.25rem;
        border: none;
        outline: none;
        border-radius: 2rem;
        color: $color-black;
        background: $color-light;
        text-transform: unset;
        text-rendering: optimizeLegibility;
      }

      .input-submit {
        font-family: inherit;
        font-size: clamp(13px, 2.5vw, 17px);
        line-height: inherit;
        cursor: pointer;
        min-width: 40%;
        height: auto;
        padding: 0.6rem 1rem;
        border: none;
        outline: none;
        border-radius: 2rem;
        color: $color-white;
        background: $color-blue;
        box-shadow: $shadow-medium;
        text-transform: capitalize;
        text-rendering: optimizeLegibility;
        margin: auto;

        &:hover {
          background-color: #3d88eb;
        }
        @media screen and (max-width: 700px) {
          padding: 0.5rem 1rem;
        }
      }
    }

    .striped {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;

      &-line {
        flex: auto;
        flex-basis: auto;
        border: none;
        outline: none;
        height: 2px;
        background: $color-grayish;
      }

      &-text {
        font-family: inherit;
        font-size: clamp(13px, 2.5vw, 18px);
        font-weight: 500;
        line-height: inherit;
        color: $color-black;
        margin: 0 1rem;
      }
    }

    .method {
      &-control {
        margin-bottom: 1rem;
      }

      &-action {
        position: relative;
        font-family: inherit;
        font-size: clamp(14px, 2.5vw, 16px);
        font-weight: 500;
        line-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0.35rem 1.25rem;
        outline: none;
        border: 2px solid $color-grayish;
        border-radius: 2rem;
        color: $color-black;
        background: $color-white;
        text-transform: capitalize;
        text-rendering: optimizeLegibility;
        transition: all 0.35s ease;
        cursor: pointer;

        .dual-ring::after {
          border: 3.5px solid #fff;
          border-color: #336fa7 transparent #336fa7 transparent;
        }

        @media screen and (max-width: 700px) {
          padding: 0.3rem 0.7rem;
        }

        * {
          margin: 0 0.4rem;
        }

        // &:hover {
        //   background: $color-light;
        // }

        svg {
          @media screen and (max-width: 700px) {
            width: 31px;
            height: 31px;
          }
        }
      }
    }
  }
}

.signup {
  margin: auto;
}

.dual-ring {
  width: inherit;
  height: inherit;
}

.dual-ring:after {
  content: " ";
  text-align: center;
  margin: auto;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3.5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
