$navy-light-blue: rgb(34, 34, 240);
$blue: #2666cf;
$white: #ffffff;
$light-grey: rgb(73, 80, 85);
$nav-hover-white: rgba(255, 255, 255, 0.4);
$nav-hover-blue: #95b3e4;
$light-sky-blue: #5e91e2;

@mixin add-icon-stoke-prop($stroke-width, $fill-color) {
  stroke: none;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-miterlimit: 10;
  fill-rule: nonzero;
  opacity: 1;
  stroke-linejoin: miter;
  stroke-width: $stroke-width;
  fill: $fill-color;
}

@mixin display-flex-prop {
  display: flex;
  align-items: center;
  @content;
}

.pre-heading {
  color: orange !important;
  font-family: "Roboto", sans-serif;
}

.logo {
  width: 45px;
  height: 45px;
}

.main-head {
  display: flex;
  gap: 0.5rem;
}

.auth {
  a {
    text-decoration: none;
    margin: 0 2rem;
  }
}

.add-scream-icon {
  g {
    g {
      @include add-icon-stoke-prop(0, none);

      path {
        @include add-icon-stoke-prop(1, rgb(0, 0, 0));
      }
    }
  }
}

.bg-blue {
  background-color: $blue;
}

.navbar {
  padding: 0rem 25px 0rem 20px;
  height: 65px;
  @include display-flex-prop();
  user-select: none;
  z-index: 990;

  svg {
    stroke: $white;
    fill: $white;
  }

  &.navbar-dark {
    * {
      color: $white;
    }
  }

  .navbar-nav {
    .nav-list {
      list-style: none;
      padding-left: 0;
      font-size: 1.1rem;
      @include display-flex-prop();

      li {
        margin: 0 0.4rem;
      }
    }
  }

  .navbar-btn {
    height: 25px;
    width: 30px;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    display: none;

    .bar {
      border-radius: 5px;
      height: 4px;
      width: 100%;
      background-color: white;
      position: absolute;
      transition: transform getAnimationDuration(0.5s);
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
        transform-origin: right;
        transform: scaleX(0.8) translateY(-50%);
      }
      &:nth-child(3) {
        top: 100%;
        transform-origin: right;
        transform: scaleX(1.1) translateY(-100%);
      }
    }

    &.active {
      .bar {
        transform-origin: center;
        top: 50%;
        &:nth-child(1) {
          transform: translate(0, -50%) scale(1) rotate(45deg);
        }
        &:nth-child(2) {
          transform: scaleX(0) translateY(-50%);
        }
        &:nth-child(3) {
          transform: translate(0, -50%) scale(1) rotate(-45deg);
        }
      }
    }
  }
}

.nav-brand {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.navbar-mid-nav {
  @include display-flex-prop();
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.nav-mid-list {
  list-style: none;
  display: flex;
  padding-left: 0;
  height: 100%;
}

.nav-mid-list-link {
  margin: 0 1.5vw;
  padding: 0.5rem 2rem;
  border-radius: 10px;

  svg {
    margin-top: 0.1rem;
  }
  &:hover {
    background: $nav-hover-white;
  }

  &.active {
    background: $nav-hover-white;
  }
}

.content {
  width: 100%;
  height: 100vh;
  background-color: #eee;
  font-weight: bolder;
  font-size: 2rem;
  @include display-flex-prop() {
    justify-content: center;
  }
}

.navbar-actions-icon {
  border-radius: 80%;
  padding: 0.38rem 0.55rem 0.3rem 0.55rem;
  background-color: $light-sky-blue;
  cursor: pointer;

  &.active {
    background-color: $nav-hover-blue;
  }

  &:hover {
    background-color: $nav-hover-blue;
  }
}

.my-profile-link {
  &.active .profile-icon {
    background: $nav-hover-white !important;
  }
}

.profile-icon {
  display: none;
  position: absolute;
  right: 270px;
  top: 10px;
  cursor: pointer;
  border-radius: 25px;
  padding: 0.25rem 0.55rem 0.25rem 0.35rem;
  gap: 0.6rem;

  &:hover {
    background-color: $nav-hover-white;
    touch-action: manipulation;
  }

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  }

  @media (min-width: 1024px) {
    display: flex;
  }
}

.unread-notification-icon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  right: 4.2rem;
  top: 5px;
  background-color: #0944a3;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(12px, 1.6vw, 16px);
  z-index: 995;
}

.options {
  position: absolute;
  right: 1rem;
  min-height: 160px;
  width: 28vw;
  max-width: 300px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fcfcfc;
  z-index: 990;
  top: 3.05rem;
  border-radius: 10px;
}

.options-wrapper {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.profile-option {
  display: flex;
  justify-content: left;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.2rem 0;
  color: black;
  word-wrap: break-word;
  word-break: keep-all;

  .image-container {
    width: 6vw;
    max-width: 60px;

    img {
      border-radius: 50%;
      min-width: 60px;
      min-height: 60px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    font-size: clamp(13.5px, 1.6vw, 18px);
    width: 100%;
    justify-content: center;
    overflow: auto;

    div,
    strong {
      margin: 0 0.6rem;
    }
  }

  &:hover {
    background-color: #dcdde4;
    border-radius: 7px;
  }
}

.logout-btn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.2rem 0;
  font-size: clamp(14px, 1.6vw, 18px);
  color: black;

  div {
    height: 36px;
    width: 36px;
    display: inline-flex;
    position: relative;
    align-items: center;
  }

  i {
    background-image: url("https://static.xx.fbcdn.net/rsrc.php/v3/yi/r/QnBF2xVFFiX.png");
    background-position: 0px -46px;
    background-size: auto;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    filter: invert(40%) sepia(6%) hue-rotate(185deg);
    -webkit-filter: invert(40%) sepia(6%) hue-rotate(185deg);
  }

  &:hover {
    background-color: #dcdde4;
    border-radius: 7px;
  }
}

.hamburger-action {
  margin-left: auto;
  height: 100%;
  display: none;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .navbar-nav,
  .navbar-mid-nav {
    display: none;
    display: none;
  }

  .navbar .navbar-btn {
    display: block;
  }
}
