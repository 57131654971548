.heading-add-scream {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 425px) {
    font-size: 16px;
  }
}

.add-scream-box {
  margin: 0.8rem 0 0;

  .textarea {
    width: 100%;
    height: 8rem;
    outline: none;
    resize: none;
    font-family: "Roboto", sans-serif;
    border-radius: 8px;
    border-color: rgb(175, 175, 187);
    padding: 0.5rem;
    color: black;

    &.invalid {
      border: 1px solid red;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #5d93eb;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  button {
    background-color: rgb(55, 124, 236);
    color: white;
    padding: 0.5rem 1.4rem;
    font-size: 16px;
    border-radius: 5px;
    margin: 0.5rem 0 0;
    cursor: pointer;

    &:hover {
      background-color: rgb(8, 99, 246);
    }
    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 0.4rem 1rem;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .error-msg {
    margin-left: auto;

    color: red;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.dual-ring {
  width: inherit;
  height: inherit;
}

.dual-ring:after {
  content: " ";
  text-align: center;
  margin: auto;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3.5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
