.comment-section {
  margin: 0.5rem 0;
}

.header {
  font-size: clamp(12px, 3vw, 18px);
}

.comment-area {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 2px solid black;
  resize: none;
  font-family: "Noto Sans";
  font-size: clamp(12px, 3vw, 15px);

  &.invalid {
    border-color: red;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #5d93eb;
  }

  &:focus {
    border-color: #2666cf;
  }
}

.add-comment-btn {
  margin: 0.1rem 0;
  padding: 0.4rem 0;
  color: white;
  font-size: clamp(12.5px, 2.2vw, 14.5px);
  background-color: rgb(55, 124, 236);
  border-radius: 5px;
  width: 7rem;
  cursor: pointer;

  &:hover {
    background-color: rgb(8, 99, 246);
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
    padding: 0.4rem 0.3rem;
  }
}

.error-msg {
  margin-left: auto;

  color: red;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.dual-ring:after {
  content: " ";
  text-align: center;
  margin: auto;
  display: block;
  width: 15px;
  height: 12px;
  border-radius: 50%;
  border: 3.5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1s linear infinite;

  @media screen and (max-width: 768px) {
    width: 14px;
    height: 11px;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
