.actions-body {
  display: flex;
  justify-content: start;
  margin: 0.5rem 0 0;

  button {
    display: flex;
    align-items: center;
    font-size: clamp(12px, 1.1vw, 15px);
    padding: 0;
    svg {
      width: 1rem;
    }
  }
  > span {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      font-size: clamp(12px, 1.8vw, 15px);
      margin: 0 0.2rem;
      svg {
        width: 1rem;
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;
      font-size: clamp(12px, 1.8vw, 15px);
      margin: 0 0.2rem;
      svg {
        width: 1.2rem;
        margin-top: 0.25rem;
      }
    }
  }

  .actions_text {
    display: flex;
    justify-content: center;
    margin: 0 0.5rem;
  }
}

.show-scream-box {
  width: 100%;
  padding: 0 0.5rem;

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  .scream-body {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0 0.5rem 0 0;
    font-size: clamp(12px, 3vw, 16px);
    height: 8rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #5d93eb;
    }
  }

  .profile {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    img {
      width: 12vw;
      height: 12vw;
      min-height: 50px;
      min-width: 50px;

      max-height: 80px;
      max-width: 80px;
      object-fit: cover;
      border-radius: 50%;
      margin: 0.5rem 0;
    }

    .user-details {
      margin: 0.5rem 0.8rem;
      width: 50%;

      a {
        color: #2666cf;
      }

      div {
        &:nth-child(1) {
          font-size: clamp(14px, 2.8vw, 18px);
        }

        &:nth-child(2) {
          font-size: clamp(11px, 2.4vw, 15px);
          color: grey;
        }
      }
    }
  }
}
