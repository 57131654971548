$blue: #2666cf;

.profile {
  height: 100%;
}

.upper-container {
  .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    background: hsla(154, 100%, 76%, 1);
    background: hsla(239, 100%, 67%, 1);
    border-radius: 5px 5px 0 0;
    min-height: 120px;

    background: linear-gradient(
      90deg,
      hsla(239, 100%, 67%, 1) 0%,
      hsla(187, 100%, 89%, 1) 100%
    );

    background: -moz-linear-gradient(
      90deg,
      hsla(239, 100%, 67%, 1) 0%,
      hsla(187, 100%, 89%, 1) 100%
    );

    background: -webkit-linear-gradient(
      90deg,
      hsla(239, 100%, 67%, 1) 0%,
      hsla(187, 100%, 89%, 1) 100%
    );

    img {
      width: 12vw;
      height: 12vw;
      max-width: 150px;
      min-height: 120px;
      max-height: 150px;
      text-align: center;
      object-fit: cover;
      border-radius: 50%;
      margin: 0.5rem 0;
    }
  }
}

.lower-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;

  * {
    margin: 0.3rem 0;

    @media screen and (max-width: 1050px) {
      margin: 0.25rem 0;
    }
  }

  .profile-link {
    a {
      color: $blue;
      cursor: pointer;
      font-size: clamp(14px, 1.7vw, 20px);
    }
  }

  .name {
    font-size: clamp(17px, 1.5vw, 20px);
    color: rgba($color: #000000, $alpha: 0.6);
  }

  .website {
    color: $blue;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      fill: #2666cf;
      margin: 0 0.35rem;
    }
  }

  .location {
    display: flex;
    align-items: center;
    word-wrap: normal;

    svg {
      width: 18px;
      height: 18px;
      fill: #2666cf;
      margin: 0 0.2rem;
    }
  }

  .bio {
    text-align: center;
    word-wrap: break-word;
    overflow-y: auto;
    max-height: 4rem;
    width: 15rem;
    padding-right: 5px;
  }

  .bio,
  .location,
  .joined,
  .website {
    color: black;
    font-size: clamp(12px, 1.3vw, 15px);
  }

  .joined {
    display: flex;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      fill: #2666cf;
      margin: 0 0.45rem;
    }
    span {
      margin: 0 0.3rem;
    }
  }
}
