$color-primary: #31d47d;
$border-radius: 5px;

@mixin showcase() {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin: auto;
  position: relative;
  border: 2px dotted black;
  &::before {
    content: "Upload Photo";
    position: absolute;
    top: 4rem;
    left: 1.7rem;
  }

  @content;
}

.wrapper {
  min-height: 17.5rem;
}

.image-edit-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
}

.image-label {
  font-size: clamp(12px, 1vw, 16px);
  font-weight: 600;
}

.image-input-box {
  width: 10rem;
  border: 1px solid rgba(185, 163, 163, 0.202);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: $border-radius;
  cursor: pointer;
  padding: 0.2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  &:hover {
    color: white;
    background-color: purple;
    transition: all 200ms ease-out;
  }
}

.selected {
  color: white;
  background-color: purple;
}

.not-selected {
  background-color: white;
  color: purple;
}

.hidden {
  display: none;
}

.image-showcase-box {
  @include showcase();
}

.image-showcase {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.remove-showcase {
  @include showcase() {
    border: none;
    position: static;

    &::before {
      content: "";
      position: static;
    }
  }
}

.footer {
  margin-top: 1.2rem;
}

.save-btn {
  color: white;
  background-color: $color-primary;
  padding: 6px 16px;
  max-width: 4rem;
  min-width: 4rem;
  border: 0;
  border-radius: $border-radius;
  transition: all 150ms ease-out;
  font-size: 16px;
  margin-left: auto;
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: darken($color-primary, 14%);
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.sub-footer-part {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.validate {
  font-size: 14px;
  color: red;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.dual-ring:after {
  content: " ";
  text-align: center;
  margin: auto;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1s linear infinite;

  @media screen and (max-width: 768px) {
    width: 14px;
    height: 11px;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
