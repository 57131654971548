$helvetica: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

.error-body {
  min-height: calc(100vh - 65px);
  background-color: rgb(218, 228, 238);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.not-found-cloud {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 120px auto 30px auto;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 10px 5px -7px #666, 75px 1px 0 -3px #fff, 78px 4px 6px -5px #666,
    -77px 1px 0 -3px #fff, -80px 4px 6px -5px #666, -20px -50px 0 10px #fff,
    -21px -54px 6px 8px #666, 53px -50px 0 -17px #fff, 56px -53px 6px -20px #666;
  &::after {
    content: ("Uh oh...");
    font-family: $helvetica;
    font-weight: 500;
    color: #444;
    font-size: 47px;
    letter-spacing: -3px;
    position: absolute;
    top: 26px;
    right: 6px;

    @media screen and (max-width: 768px) {
      font-size: 45px;
      top: 2px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 92px;
    height: 92px;
  }
}

.not-found-text {
  font-family: $helvetica;
  text-align: center;
  font-size: clamp(20px, 3vw, 36px);
  color: black;
  font-weight: 300;
  margin: 0 1.5rem;
  display: flex;
  flex-direction: column;
}

.home-btn {
  font-size: clamp(11px, 2vw, 16px);
  font-weight: 500;
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  color: #fff;
  background-color: rgb(29, 150, 55);
  margin: 1.5rem;
  border-radius: 5px;

  &:hover {
    background-color: rgb(46, 187, 77);
  }
}
