.notification-item {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0.5rem 0;
  border-radius: 8px;

  .image-container {
    width: 10vw;
    max-width: 60px;
    img {
      border-radius: 50%;
    }
  }

  &:hover {
    background-color: #dcdde4;
  }
}

.lower-container {
  display: flex;
  flex-direction: column;
  font-size: clamp(10px, 1.5vw, 15px);
  max-width: 72%;
  margin: 0 0.5rem;

  .timeago {
    color: rgb(34, 34, 240);
  }
}
.notification-user-info {
  word-break: normal;
}

.notification-link {
  color: #000;
}

@media screen and (max-width: 700px) {
  .notification-item {
    margin: 0.5rem 0.5rem;
  }

  .lower-container {
    width: 100%;
    font-size: clamp(12px, 2.5vw, 15px);
  }
  .image-container {
    min-width: 50px;
    min-height: 50px;
    img {
      min-width: 50px;
      min-height: 50px;
      border-radius: 50%;
    }
  }
}
