$pressed-color: rgb(63, 225, 106);
// $rest-color: #0944a3;

.single-dot-container {
  position: absolute;
  right: 8.1rem;
  top: 2px;
}

.dot-highlight {
  position: absolute;
  top: 0px;
  right: 0;
  height: 20px;
  width: 20px;
  background: $pressed-color;
  border-radius: 100px;
  opacity: 0;
}

.right {
  animation-name: right-dot-highlight-opacity-animation;
  animation-duration: 1.61s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.dot-base {
  position: absolute;
  top: 0px;
  margin-top: 0px;
  right: 0;
  height: 20px;
  width: 20px;
  background: $pressed-color;
  border-radius: 100px;
}

.rightDotContainer {
  animation-name: right-container-translate-animation;
  animation-duration: 1.61s;
  transform-origin: bottom center;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes right-container-translate-animation {
  0% {
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.4, -0.04, 0.96, 0.8);
  }
  12.64% {
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.05, 0, 0.7, 1);
  }
  15.79% {
    transform: translate3d(0px, 17px, 0px) scale(1.15, 0.68);
    animation-timing-function: linear;
  }
  18.95% {
    transform: translate3d(0px, 17px, 0px) scale(1.15, 0.68);
    animation-timing-function: cubic-bezier(0.3, 0, 0.67, 1);
  }
  22.11% {
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.3, 0, 0.67, 1);
  }
  71.1% {
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.05, 0, 0.91, 1);
  }
  74.2% {
    transform: translate3d(0px, 12px, 0px) scale(1.05, 0.8);
    animation-timing-function: linear;
  }
  77.3% {
    transform: translate3d(0px, 12px, 0px) scale(1.05, 0.8);
    animation-timing-function: cubic-bezier(0.45, 0, 0.67, 1);
  }
  80.5% {
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
    animation-timing-function: linear;
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
  }
}

@keyframes right-dot-highlight-opacity-animation {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.4, -0.04, 0.96, 0.8);
  }
  12.64% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.05, 0, 0.7, 1);
  }
  15.79% {
    opacity: 1;
    animation-timing-function: linear;
  }
  18.95% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.3, 0, 0.67, 1);
  }
  22.11% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.3, 0, 0.67, 1);
  }
  71.1% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.05, 0, 0.91, 1);
  }
  74.2% {
    opacity: 1;
    animation-timing-function: linear;
  }
  77.3% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.45, 0, 0.67, 1);
  }
  80.5% {
    opacity: 0;
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
  }
}
