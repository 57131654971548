@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(152, 182, 238);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(22, 22, 145);
  border-radius: 8px;
}

::-moz-selection {
  /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  background: rgb(174, 224, 240);
}

/* @media screen and (max-width: 700px) {
  body {
    overflow: hidden;
  }
} */
