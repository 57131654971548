.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}

.modal {
  position: fixed;
  left: calc(50% - 15rem);
  width: 30rem;
  background-color: white;
  z-index: 1999;
  animation: slide-down 400ms ease-out forwards;
  border-radius: 8px;

  svg {
    cursor: pointer;
    margin-left: auto;
    @media screen and (max-width: 768px) {
      width: 16px;
      height: 16px;
    }
  }

  .wrapper {
    height: 100%;
    width: 100%;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  @media screen and (max-width: 768px) {
    width: 24rem;
    left: calc(50% - 12rem);
  }

  @media screen and (max-width: 540px) {
    width: 90%;
    left: 5%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.add-scream {
  top: calc(50% - 9rem);
  min-height: 12rem;
}

.show-scream,
.profile-scream,
.random-user-scream {
  top: calc(50% - 18rem);
  min-height: 20rem;

  @media screen and (max-width: 480px) {
    top: calc(50% - 17rem);
  }
}

.edit-profile {
  top: calc(50% - 14rem);
  min-height: 24rem;
}

.update-profile-picture {
  top: calc(50% - 207px);
  min-height: 17rem;
}
