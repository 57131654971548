$blue: #2666cf;

.main {
  max-width: 815px;
  display: flex;
}

.scream-profile-image {
  display: block;
  width: 17vw;
  max-width: 170px;
  min-width: 130px;
  height: 13vw;
  max-height: 170px;
  min-height: 130px;
  border-radius: 5px;

  img {
    min-width: 130px;
    max-width: 170px;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
}

.scream-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
  width: 100%;
  max-width: 600px;
  word-break: break-all;

  .upper-body {
    overflow: auto;
  }

  .name {
    color: $blue;
    font-size: clamp(14px, 1.4vw, 20px);
  }

  .timeago {
    color: rgba($color: #000000, $alpha: 0.5);
    font-size: clamp(12px, 1.1vw, 15px);
  }

  .body {
    color: black;
    font-size: clamp(12.5px, 1.1vw, 15px);
    margin: 0.5rem 0;
    word-wrap: break-word;

    @media screen and (max-width: 1200px) {
      margin: 2px 0;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    font-size: clamp(14px, 1.3vw, 16px);
    margin-top: auto;
  }
}

.actions_one {
  display: flex;
  justify-content: center;

  > span {
    display: flex;
    align-items: center;
    margin: 0 0.5rem;

    &:nth-child(2) {
      svg {
        margin-top: 0.4rem;
      }
    }
  }

  .actions_text {
    font-size: clamp(12px, 1vw, 14px);
  }
}

.actions-two {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem;
  font-size: clamp(13px, 1.3vw, 16px);
  text-align: center;

  span {
    cursor: pointer;
    margin-top: auto;
  }

  @media screen and (max-width: 1024px) {
    margin: 0.6rem 0.6rem 0.8rem;
  }

  svg {
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      width: 22px;
      height: 22px;
    }
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.show {
  display: flex;
  align-items: center;
}

.show-mobile {
  display: none;
}

.delete-placeholder {
  width: 24px;
  height: 24px;
  visibility: "hidden" !important;
}

// mobile resolution

@media screen and (max-width: 700px) {
  .main {
    flex-direction: column;
  }

  .scream-profile-image {
    width: 45vw;
    max-width: 300px;
    height: 32vw;
    max-height: 180px;
    display: block;
    border-radius: 5px;

    img {
      width: 45vw;
      max-width: 300px;
      height: 32vw;
      max-height: 180px;
      border-radius: 5px;
      object-fit: cover !important;
    }
  }

  .scream-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .upper-body {
      padding: 0.5rem 0.5rem;
      height: 28vw;
      max-height: 140px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: clamp(14px, 1.3vw, 16px);
      padding: 0;
    }
  }

  .actions_one span {
    margin: auto;
    cursor: pointer;
  }

  .show,
  .delete-placeholder {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .actions-two {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    font-size: clamp(13px, 1.3vw, 16px);

    svg {
      width: 17px;
    }
  }
}

@media screen and (max-width: 460px) {
  .main {
    height: 100%;
  }

  .scream-profile-image {
    width: 100%;
    height: 30rem;
    display: block;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover !important;
    }
  }

  .scream-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .upper-body {
      padding: 0.5rem 0.5rem;
      height: 100%;
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: clamp(14px, 1.3vw, 16px);
      padding: 0;
    }
  }
}
