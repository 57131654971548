.nav-icon {
  display: none;

  @media screen and (max-width: 700px) {
    display: block;
    width: 45px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin: 0.8rem auto;
  }
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: white;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.nav-icon span:nth-child(odd) {
  left: 1px;
  border-radius: 9px 0 0 9px;
}

.nav-icon span:nth-child(1),
.nav-icon span:nth-child(2) {
  top: 0px;
}

.nav-icon span:nth-child(3),
.nav-icon span:nth-child(4) {
  top: 14px;
}

.nav-icon span:nth-child(5),
.nav-icon span:nth-child(6) {
  top: 28px;
}

.nav-icon.open span:nth-child(1),
.nav-icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-icon.open span:nth-child(2),
.nav-icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-icon.open span:nth-child(1) {
  left: 5px;
  top: 6px;
}

.nav-icon.open span:nth-child(2) {
  left: calc(50% - 1px);
  top: 6px;
}

.nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav-icon.open span:nth-child(5) {
  left: 4px;
  top: 22px;
}

.nav-icon.open span:nth-child(6) {
  left: calc(50% - 1px);
  top: 21px;
}
