@mixin profile-action-buttons(
  $backgroundColor,
  $onHoverBackgroundColor,
  $fontSize,
  $mobileFontSize
) {
  padding: 0.5rem;
  border-radius: 5px;
  color: white;
  background-color: $backgroundColor;
  cursor: pointer;
  font-size: $fontSize;

  &:hover {
    background-color: $onHoverBackgroundColor;
  }

  @media screen and (max-width: 768px) {
    font-size: $mobileFontSize;
  }

  @content;
}

.profile-background {
  width: 100%;
  height: 14rem;
  background: url("./profile-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-img-container {
  width: 160px;
  height: 160px;
  position: relative;
  top: 5rem;
  left: calc(50% - 80px);
  border-radius: 50%;

  @media screen and (max-width: 1024px) {
    width: 130px;
    height: 130px;
    left: calc(50% - 65px);
  }

  @media screen and (max-width: 480px) {
    width: 120px;
    height: 120px;
    left: calc((100% - 120px) / 2);
  }

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.center {
  width: 100%;
  height: calc(100vh - 65px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-username {
  position: relative;
  margin: 7rem 0 0 5rem;
  width: 70%;
  text-align: center;
  font-size: 2rem;
  word-wrap: break-word;
  word-break: keep-all;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    margin-top: 5rem;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    margin-top: 4rem;
    font-size: 1.2rem;
  }
}

.profile-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem;

  .profile-options-first {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 8px;

    .profile-option-1,
    .profile-option-2 {
      @include profile-action-buttons(
        rgba(35, 45, 162, 0.493),
        rgba(35, 45, 162, 0.293),
        15px,
        13px
      );
    }
  }

  .profile-options-two {
    margin-left: auto;
    .profile-option-3 {
      @include profile-action-buttons(transparent, transparent, 17px, 15px) {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-size: 18px;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

.profile-main {
  width: 95%;
  max-width: 60rem;
  margin: auto;
  padding-top: 8rem;

  @media screen and (max-width: 700px) {
    width: 90%;
  }
}

.no_screams {
  text-align: center;
}

.divider {
  width: 100%;
  background-color: rgb(34, 85, 227);
  height: 2px;
  margin: 0.2rem 0;
}

.screams {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    grid-row-gap: 0.8rem;
  }

  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr;
  }
}
