.container {
  display: grid;
  grid-template-columns: 1.7fr 0.7fr;
  grid-template-rows: 1fr;
  grid-template-areas: "left-sub-container right-sub-container";
  margin: 0 auto 1rem;
  max-width: 70rem;
  padding: 1.5rem 0;
  width: 88%;
  grid-column-gap: 2.5rem;

  .no_screams {
    text-align: center;
    font-size: 1rem;
  }

  @media screen and (max-width: 1024px) {
    width: 94%;
  }

  @media screen and (max-width: 990px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-template-areas: "left-sub-container";
  }

  .left-sub-container {
    grid-area: left-sub-container;
    width: 100%;
    margin: auto;
    height: 100%;
    @media screen and (max-width: 700px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }

    @media screen and (max-width: 460px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .right-sub-container {
    grid-area: right-sub-container;
    @media screen and (max-width: 990px) {
      display: none;
    }
  }
}

.add-scream-mobile {
  display: none;

  @media screen and (max-width: 700px) {
    display: block !important;
    position: fixed;
    right: 8%;
    bottom: 4%;
    background-color: rgb(55, 124, 236);
    fill: white;
    padding: 0.4rem 0.6rem;
    border-radius: 50%;
    &:hover {
      background-color: rgb(8, 99, 246);
    }

    li {
      list-style: none;
      svg {
        position: relative;
        top: 2px;
      }
    }
  }
}

.spinner {
  text-align: center;
  margin: 2rem auto 3rem;
}
