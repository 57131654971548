.comment-list {
  height: 9rem;
  overflow-y: auto;

  @media screen and (max-width: 480px) {
    height: 7.5rem;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #5d93eb;
  }
}
