@mixin card-base-prop {
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -moz-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 1rem;
  border-radius: 5px;
}

@mixin scream-base {
  width: 100%;
  height: 13vw;
  max-height: 170px;
  min-height: 130px;
  max-width: 815px;
}

.home-scream {
  @include scream-base();
  @include card-base-prop();
  margin-left: auto;
  margin-right: auto;
}

.profile-scream,
.random-user-scream {
  @include scream-base();
  @include card-base-prop();
}

.profile {
  max-width: 100%;
  height: 36vw;
  max-height: 26.5rem;
  @include card-base-prop();
}

@media screen and (max-width: 700px) {
  .home-scream,
  .profile-scream,
  .random-user-scream {
    width: 45vw;
    height: 77vw;
    max-height: 400px;
    max-width: 300px;
    margin: auto;
    min-height: 360px;
  }
}

@media screen and (max-width: 460px) {
  .home-scream,
  .profile-scream,
  .random-user-scream {
    width: 100%;
    height: 50rem;
    margin: auto;
  }
}
