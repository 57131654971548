.notifications {
  position: absolute;
  right: 1rem;
  height: 90vh;
  z-index: 990;
  top: 3.05rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fcfcfc;
  width: 31vw;
  max-width: 330px;
}

.notification-text {
  text-align: center;
  margin: 0.8rem 0;
  font-size: clamp(14px, 1.6vw, 20px);
}

.center {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-wrapper {
  padding: 0 0.5rem;
  height: 100%;
  overflow-y: auto;
  word-break: break-all;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  @media screen and (max-width: 768px) {
    padding: 0 0rem;
    margin: auto;
  }
}

.close {
  display: none;
}

@media screen and (max-width: 700px) {
  .notifications {
    position: fixed;
    width: 95%;
    height: 100vh;
    top: 100%;
    max-width: 100%;
    border-radius: 30px 30px 0 0;
    right: calc((100% - 95%) / 2);

    &.open {
      animation: slide-up 0.4s forwards 0s;
      -moz-animation: slide-up 0.4s forwards 0s;
      -o-animation: slide-up 0.4s forwards 0s;
      -webkit-animation: slide-up 0.4s forwards 0s;
    }

    .notification-text {
      font-size: clamp(15px, 3vw, 18px);
    }

    @keyframes slide-up {
      0% {
        top: 100%;
      }

      100% {
        top: 3.05rem;
      }
    }
  }

  .close {
    display: block;
    text-align: right;
    margin: 0.8rem 1.2rem 0 1.2rem;

    svg {
      width: 17px;
    }
  }
}
