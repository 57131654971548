// $primary: rgb(19, 19, 19);

.chat-right-panel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.8rem 0;

  @media screen and (max-width: 480px) {
    &.close {
      display: none;
    }

    &.open {
      position: absolute;
      background-color: white;
      border: 0;
      padding-top: 0;
      display: block;
    }
  }
}

.chat-header {
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
    background-color: rgba(214, 235, 248, 0.8);
    backdrop-filter: blur(1px);
    width: 100%;
    padding: 0.4rem 0.5rem;
    position: absolute;
    z-index: 10;

    .back-btn {
      display: flex;
      position: sticky;
      top: 0.2rem;
      font-size: 0.65rem;
      background-color: rgba(129, 218, 171, 1);
      color: white;
      padding: 0.3rem 0.3rem;
      border-radius: 50%;
      align-items: center;
      gap: 2px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.chat-main-area {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-area {
  height: 100%;
  overflow-y: scroll;
  padding: 0.5rem 0.8rem;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;

  @media screen and (max-width: 480px) {
    border-top: 0;
    padding: 2rem 0.8rem 0.5rem;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-message {
  text-align: center;
  font-size: clamp(15px, 2.2vw, 20px);
  margin: 0 0.5rem;
}

.error-message-img {
  width: 40px;
  height: 40px;

  @media screen and (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
