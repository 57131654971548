.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-chats-message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    width: 9rem;
    height: 9rem;

    @media screen and (max-width: 1024px) {
      width: 7rem;
      height: 7rem;
    }
  }
}
