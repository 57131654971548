.chat-panel {
  position: relative;
  width: 100%;
  display: flex;
  background-color: white;
  z-index: 900;
  height: calc(100vh - 65px);
}

.chat-left-panel {
  display: block;
  width: 22%;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid gray;
  overflow-y: auto;
  left: 0;
  top: 0;
  padding: 1rem 0.5rem;
  height: 100%;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: auto;
    border: 0;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-chats-message {
  font-size: clamp(13px, 1.2vw, 18px);
  text-align: center;
}

.chat-placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.8rem 0;
  background-image: url("chat-panel-background.webp");
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 480px) {
    display: none;
  }

  div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: clamp(14px, 2.5vw, 35px);
    margin: 2rem 0;
    color: rgba(116, 113, 113, 0.849);
  }
}

.close {
  display: none;
}
